import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRestaurantName, fetchMenuItems, createOrder, requestBill } from "./api/api";
import OrderSubmitForm from "./components/OrderSubmitForm/OrderSubmitForm";
import OrderModal from "./components/OrderModal/OrderModal";
import Snackbar from "./components/Snackbar/Snackbar";
import Footer from "./components/Footer/footer";
import "./MenuPage.css";

const MenuPage = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsName, setSelectedItemsName] = useState([]);
  const [extraInfo, setExtraInfo] = useState("");
  const [restaurantTitle, setRestaurantTitle] = useState("");
  const [snackbar, setSnackbar] = useState({
    message: "",
    color: "",
    show: false,
  });
  const { restaurantId, tableNumber } = useParams();
  const [itemQuantities, setItemQuantities] = useState({});
  const [groupItems, setGroupItems] = useState([]);
  const [openSubGroup, setOpenSubGroup] = useState(null);
  const MAX_QUANTITY = 20;

  const toggleSubGroup = (subgroup) => {
    setOpenSubGroup(openSubGroup === subgroup ? null : subgroup);
  };

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const formatMenuItems = (menuItemsArray) => {
    const groupArr = [];
    menuItemsArray.forEach((menuItem) => {
      groupArr.push(menuItem.subgroup);
    });
    setGroupItems(removeDuplicates(groupArr));
  };

  useEffect(() => {
    fetchRestaurantName(restaurantId);
    fetchMenuItemsData(restaurantId, tableNumber);
  }, []);

  const handleShowSnackbar = (message, color) => {
    setSnackbar({ message, color, show: true });

    setTimeout(() => {
      setSnackbar((prevSnackbar) => ({ ...prevSnackbar, show: false }));
      window.location.reload();
    }, 4000);
  };

  const validatePathParams = (restaurantId, tableNumber) => {
    if (Number(tableNumber) > 0 && Number(restaurantId) > 0) {
      return true;
    }
    return false;
  };

  const fetchRestaurantName = async () => {
    try {
      const data = await getRestaurantName(restaurantId);
      setRestaurantTitle(data.data.name);
    } catch (error) {
      console.error('Error fetching restaurant name:', error);
    }
  };

  const fetchMenuItemsData = async () => {
    try {
      if (validatePathParams(restaurantId, tableNumber)) {
        const data = await fetchMenuItems(restaurantId);
        formatMenuItems(data.data);
        setMenuItems(data.data);
      } else {
        throw new Error("Params are not valid.");
      }
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const handleItemIncrement = (itemId, itemName) => {
    setItemQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      if ((newQuantities[itemId] || 0) < MAX_QUANTITY) {
        newQuantities[itemId] = (newQuantities[itemId] || 0) + 1;
      }
      return newQuantities;
    });

    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    setSelectedItemsName((prevSelectedItemsNames) => [
      ...prevSelectedItemsNames,
      itemName,
    ]);
  };

  const handleItemDecrement = (itemId, itemName) => {
    setItemQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      if (newQuantities[itemId] > 1) {
        newQuantities[itemId] -= 1;
      } else {
        delete newQuantities[itemId];
      }
      return newQuantities;
    });

    setSelectedItems((prevSelectedItems) => {
      const index = prevSelectedItems.lastIndexOf(itemId);
      if (index > -1) {
        const newSelectedItems = [...prevSelectedItems];
        newSelectedItems.splice(index, 1);
        return newSelectedItems;
      }
      return prevSelectedItems;
    });

    setSelectedItemsName((prevSelectedItemsNames) => {
      const index = prevSelectedItemsNames.lastIndexOf(itemName);
      if (index > -1) {
        const newSelectedItemsNames = [...prevSelectedItemsNames];
        newSelectedItemsNames.splice(index, 1);
        return newSelectedItemsNames;
      }
      return prevSelectedItemsNames;
    });
  };

  const handleOrderSubmit = async () => {
    const orderData = {
      restaurant_id: restaurantId,
      menu_items_ids: selectedItems,
      extra_fields: extraInfo,
      table_number: tableNumber,
    };
    const modalElement = document.querySelector(".modal-overlay");
    try {
      const response = await createOrder(orderData);
      if (response.data) {
        handleShowSnackbar("Su pedido ha sido creado y recibido por el restaurante :)", "green");
      } else {
        handleShowSnackbar("Ocurrió un error al crear su pedido.", "red");
      }
      modalElement.classList.remove("visible");
      modalElement.classList.toggle("invisible");
    } catch (error) {
      modalElement.classList.remove("visible");
      modalElement.classList.toggle("invisible");
      handleShowSnackbar(
        "Ocurrió un error al crear su pedido intente de nuevo más tarde.",
        "red"
      );
    }
  };

  const handleCreateOrder = () => {
    const modalElement = document.querySelector(".modal-overlay");
    modalElement.classList.toggle("visible");
    modalElement.classList.remove("invisible");
  };

  const handleCloseModal = () => {
    const modalElement = document.querySelector(".modal-overlay");
    document.body.classList.remove("modal-overlay");
    modalElement.classList.remove("visible");
    modalElement.classList.toggle("invisible");
  };

  const handleRequestBill = async () => {
    try {
      const response = await requestBill(restaurantId, tableNumber);
      if (response.data) {
        handleShowSnackbar("La cuenta ha sido pedida al restaurante.", "green");
      } else {
        handleShowSnackbar("Ocurrió un error al pedir la cuenta.", "red");
      }
    } catch (error) {
      handleShowSnackbar(
        "Ocurrió un error al pedir la cuenta intente de nuevo más tarde.",
        "red"
      );
    }
  };

  return (
    <div className="menu-container">
      <h1 className="menu-title">{restaurantTitle}</h1>
      <div className="menu-grid">
        {groupItems.map((subgroup) => (
          <div key={subgroup} className="menu-subgroup">
            <h2
              className="dropdown-title"
              onClick={() => toggleSubGroup(subgroup)}
            >
              {subgroup}
              {openSubGroup === subgroup ? " ⬆️" : " ⬇️"}
            </h2>
            {openSubGroup === subgroup &&
              menuItems
                .filter((item) => item.subgroup === subgroup)
                .map((item) => (
                  <div key={item.id} className="menu-item">
                    <div className="item-details">
                      <strong className="item-name">{item.name}</strong>
                      <p className="item-description">{item.description}</p>
                      <p className="item-price">Precio: ${item.price}</p>
                    </div>
                    {item.available ? (
                      <div className="item-select-buttons">
                        <button
                          className="item-decrement-button"
                          onClick={() =>
                            handleItemDecrement(item.id, item.name)
                          }
                          disabled={!selectedItems.includes(item.id)}
                        >
                          -
                        </button>
                        <span className="item-quantity">
                          {itemQuantities[item.id] || 0}
                        </span>
                        <button
                          className="item-increment-button"
                          onClick={() =>
                            handleItemIncrement(item.id, item.name)
                          }
                          disabled={
                            (itemQuantities[item.id] || 0) >= MAX_QUANTITY
                          }
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      <button className="item-unavailable-button">
                        No disponible
                      </button>
                    )}
                  </div>
                ))}
          </div>
        ))}
      </div>
      <p className="observaciones">Observaciones</p>
      <OrderSubmitForm
        extraInfo={extraInfo}
        setExtraInfo={setExtraInfo}
        selectedItems={selectedItems}
        handleModalOpen={handleCreateOrder}
        handleRequestBill={handleRequestBill}
      />
      <OrderModal
        selectedItems={selectedItemsName}
        extraInfo={extraInfo}
        handleCloseModal={handleCloseModal}
        handleOrderSubmit={handleOrderSubmit}
      />
      <Footer/>
      <Snackbar {...snackbar} />
    </div>
  );
};

export default MenuPage;
