import React from "react";
import "./Snackbar.css";

const Snackbar = ({ message, color, show }) => {
  const snackbarStyle = {
    backgroundColor: color,
  };

  return (
    <div className={`snackbar ${show ? "show" : ""}`} style={snackbarStyle}>
      <p>{message}</p>
    </div>
  );
};

export default Snackbar;
