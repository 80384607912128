import React, { useState, useEffect } from "react";
import "./bill.css"

const BillRequests = ({ tables }) => {
  const [tableList, setTableList] = useState(tables);
  const [removedTables, setRemovedTables] = useState(new Set());

  // Synchronize tableList with tables prop whenever it changes
  useEffect(() => {
    // Filter out removed tables from the tables prop
    const filteredTables = tables.filter(table => !removedTables.has(table.random_id));
    setTableList(filteredTables);
  }, [tables, removedTables]);

  const removeTable = (randomId) => {
    setRemovedTables(prev => new Set(prev).add(randomId));
    setTableList(tableList.filter(table => table.random_id !== randomId));
  };

  if (!tableList || tableList.length === 0) {
    return <p className="no-tables-title">No hay mesas pidiendo la cuenta 💳🧾</p>;
  }

  return (
    <div>
      <h4 className="table-title">Mesas pidiendo la cuenta: </h4>
      <ul className="table-list">
        {tableList.map((table) => (
          <li key={table.random_id} className="table-box">
            Mesa {table.table_number}
            <button className="remove-button" onClick={() => removeTable(table.random_id)}>❌</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BillRequests;
