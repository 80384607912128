import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleLogin } from "./api/login";
import "./LoginPage.css"

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLoginData = async () => {
    try {
      const data = await handleLogin(username, password);
      const { data:  {token, id } } = data;
      localStorage.setItem('jwtToken', token);
      onLogin(true, id);
      navigate('/orders');
    } catch (error) {
      setError(error.message);
    }
  };

  const isFormValid = username.trim() !== '' && password.trim() !== '';

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>ScanEat 🥡📱</h1>
        {error && <p className="error-message">{error}</p>}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLoginData} disabled={!isFormValid}>Ingresar</button>
      </div>
    </div>
  );
};

export default LoginPage;
