import axios from "axios";

export const getRestaurantName = async (restaurantId) => {
  if (Number(restaurantId) > 0) {
    const response = await axios.get("/api/restaurant", {
      params: {
        restaurantId,
      },
    });
    if (response.status !== 200) {
      throw new Error("Error fetching restaurant name");
    }
    return response;
  } else {
    throw new Error("Restaurant param is not valid.");
  }
};

export const fetchMenuItems = async (restaurantId) => {
  if (Number(restaurantId) > 0) {
    const response = await axios.get("/api/menu-items", {
      params: {
        restaurantId,
      },
    });
    if (response.status !== 200) {
      throw new Error("Error fetching menu items");
    }
    return response;
  } else {
    throw new Error("Restaurant param is not valid.");
  }
};

export const getLastOrders = async (restaurantId) => {
  if (Number(restaurantId) > 0) {
    const response = await axios.get("/api/last/orders", {
      params: {
        restaurantId,
      },
    });
    if (response.status !== 200) {
      throw new Error("Error fetching last orders");
    }
    return response;
  } else {
    throw new Error("Restaurant param is not valid.");
  }
};

export const createOrder = async (orderData) => {
  if (orderData) {
    const response = await axios.post(
      "/api/orders",
      JSON.stringify(orderData),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response;
  } else {
    throw new Error("Order data not valid");
  }
};

export const createItem = async (itemData) => {
  if (itemData) {
    const response = await axios.post(
      "/api/create/item",
      JSON.stringify(itemData),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response;
  } else {
    throw new Error("Item data not valid");
  }
};

export const updateItem = async (itemId, itemData) => {
  if (itemId && itemData) {
    const response = await axios.put(
      "/api/update/item",
      JSON.stringify(itemData),
      {
        headers: { "Content-Type": "application/json" },
        params: {
          itemId,
        },
      }
    );
    return response;
  } else {
    throw new Error("Item data not valid");
  }
};

export const updateOrder = async (orderStatus, orderId) => {
  const jsonOrderStatus = {status: orderStatus}
  if (orderId && orderStatus) {
    const response = await axios.put(
      "/api/update/order",
      JSON.stringify(jsonOrderStatus),
      {
        headers: { "Content-Type": "application/json" },
        params: {
          orderId,
        },
      }
    );
    return response;
  } else {
    throw new Error("Order status not valid");
  }
};

export const requestBill = async (restaurantId, tableId) => {
  if (restaurantId && tableId) {
    const billData = {
      restaurant_id: restaurantId,
      table_id: tableId
    }
    const response = await axios.post(
      "/api/request-bill",
      JSON.stringify(billData),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response;
  } else {
    throw new Error("Item data not valid");
  }
};
