import axios from 'axios';

export const handleLogin = async (username, password) => {
  try {
    const response = await axios.post("/api/login", JSON.stringify({ username, password }), {
      headers: { 'Content-Type': 'application/json'}
    });
    if (response.status !== 200) {
      throw new Error("Invalid username or password");
    }
    return response;
  } catch (error) {
    throw new Error("Login failed try again later");
  }
};

export const validateToken = async (token) => {
  try {
    const response = await axios.get("/api/validate-token", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
    });
    return response;
  } catch (error) {
    console.error('error validating token');
  }
};
