import React, { useState } from "react";
import { createItem } from "../../api/api";
import Snackbar from "../Snackbar/Snackbar";
import "./AddProducts.css";

const AddProducts = () => {
  const [product, setProduct] = useState({
    name: "",
    price: "",
    description: "",
    available: false,
    subgroup: "",
    restaurant_id: "",
  });

  const [snackbar, setSnackbar] = useState({
    message: "",
    color: "",
    show: false,
  });

  const handleShowSnackbar = (message, color) => {
    setSnackbar({ message, color, show: true });

    setTimeout(() => {
      setSnackbar((prevSnackbar) => ({ ...prevSnackbar, show: false }));
      window.location.reload();
    }, 4000);
  };

  const getRestaurantId = () => {
    return localStorage.getItem("restaurantId");
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    product.restaurant_id = parseInt(getRestaurantId());
    try {
      const response = await createItem(product);
      if (response.data) {
        handleShowSnackbar("Su producto se creó con éxito :)", "green");
      } else {
        handleShowSnackbar("Ocurrió un error al crear su pedido.", "red");
      }
    } catch (error) {
      handleShowSnackbar(
        "Ocurrió un error al crear su pedido intente de nuevo más tarde.",
        "red"
      );
    }
  };

  return (
    <div className="products-creation-form">
      <div className="product-creator">
        <h2 className="form-wordings">Crear un nuevo producto</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" className="form-wordings">
              Nombre:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={product.name}
              onChange={handleChange}
              minLength={5}
              required
              maxLength={30}
            />
            <span className="char-count">{product.name.length}/30</span>
          </div>
          <div className="form-group">
            <label htmlFor="price" className="form-wordings">
              Precio:
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={product.price}
              onChange={handleChange}
              required
              min="0"
              step="0.01"
              onInput={(e) => {
                if (e.target.value.length > 6) {
                  e.target.value = e.target.value.slice(0, 6);
                }
              }}
            />
            <span className="char-count">{product.price.length}/6</span>
          </div>
          <div className="form-group">
            <label htmlFor="description" className="form-wordings">
              Descripción:
            </label>
            <textarea
              id="description"
              name="description"
              value={product.description}
              onChange={handleChange}
              required
              maxLength={60}
              minLength={5}
            />
            <span className="char-count">{product.description.length}/60</span>
          </div>
          <div className="form-group">
            <label htmlFor="available" className="form-wordings">
              <input
                type="checkbox"
                id="available"
                name="available"
                checked={product.available}
                onChange={handleChange}
              />
              Disponible
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="subgroup" className="form-wordings">
              Categoría:
            </label>
            <input
              type="text"
              id="subgroup"
              name="subgroup"
              value={product.subgroup}
              onChange={handleChange}
              maxLength={30}
              minLength={5}
            />
            <span className="char-count">{product.subgroup.length}/30</span>
          </div>
          <button type="submit">Crear Producto</button>
        </form>
      </div>
      <Snackbar {...snackbar} />
    </div>
  );
};

export default AddProducts;
