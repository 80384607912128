import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MenuPage from './MenuPage';
import Orders from './Orders';
import LoginPage from './LoginPage';
import Products from './Products';
import { validateToken } from './api/login';

const ProtectedRoute = ({ element: Element, isAuthenticated, restaurantId, ...rest }) => {
  return isAuthenticated ? <Element {...rest} restaurantId={restaurantId} /> : <Navigate to="/login" />;
};

const ProtectedRouteOrders = ({ element: Element, isAuthenticated, restaurantId, ...rest }) => {
  return isAuthenticated ? <Element {...rest} restaurantId={restaurantId} /> : <Navigate to="/products" />;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [restaurantId, setRestaurantId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const restaurantId = localStorage.getItem('restaurantId');
    if (token) {
      validateToken(token).then(isValid => {
        setIsAuthenticated(isValid);
        setRestaurantId(restaurantId);
      });
    }
  }, []);

  const onLoginFunction = (isLoggedIn, restaurantId) => {
    setIsAuthenticated(isLoggedIn);
    setRestaurantId(restaurantId);
    localStorage.setItem('restaurantId', restaurantId)
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/orders" /> : <LoginPage onLogin={onLoginFunction} />} />
        <Route path="/restaurant/:restaurantId/table/:tableNumber" element={<MenuPage />} />
        <Route path="/orders" element={<ProtectedRoute element={Orders} isAuthenticated={isAuthenticated} restaurantId={restaurantId} />} />
        <Route path="/products" element={<ProtectedRouteOrders element={Products} isAuthenticated={isAuthenticated} restaurantId={restaurantId} />} />
      </Routes>
    </Router>
  );
}

export default App;