import React from "react";

const OrderSubmitForm = ({
  extraInfo,
  setExtraInfo,
  selectedItems,
  handleModalOpen,
  handleRequestBill,
}) => {
  return (
    <div className="extra-info-container">
      <input
        type="text"
        className="extra-info-input"
        placeholder="Agrega información adicional..."
        value={extraInfo}
        onChange={(e) => setExtraInfo(e.target.value)}
      />
      <button
        className={`submit-order-button ${
          selectedItems.length === 0 ? "disabled" : ""
        }`}
        onClick={handleModalOpen}
        disabled={selectedItems.length === 0}
      >
        Hacer pedido
      </button>
      <button
        className="request-bill-button" 
        onClick={handleRequestBill}
      >
        Pedir la cuenta
      </button>
    </div>
  );
};

export default OrderSubmitForm;
