import React, { useState, useEffect } from "react";
import { createEventSourceOrders, closeEventSourceOrders, createEventSourceBills, closeEventSourceBills } from "./api/sse";
import { getLastOrders } from "./api/api";
import OrdersList from "./components/Order/ordersList";
import Bill from "./components/Bill/bill";
import NavigationBar from "./components/Navigation/navigationBar";
import "./Orders.css";

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9); // Generates a unique ID
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [lastOrders, setLastOrders] = useState([]);
  const [tableNumbers, setTableNumbers] = useState([]);
  const restaurantId = localStorage.getItem("restaurantId");

  const fetchLastOrders = async (restaurantId) => {
    try {
      const data = await getLastOrders(restaurantId);
      setLastOrders(data.data);
    } catch (error) {
      console.error("Error obteniendo las orders:", error);
    }
  };

  useEffect(() => {
    fetchLastOrders(restaurantId);

    const onMessageOrders = (event) => {
      const newOrder = JSON.parse(event.data);
      if (newOrder.restaurant.restaurant_id.toString() === restaurantId) {
        setOrders((prevOrders) => [...prevOrders, newOrder]);
      }
    };

    const onErrorOrders = (error) => {
      console.error("Error with SSE Orders:", error);
    };

    const onOpenOrders = () => {
      console.log("SSE Orders connection opened.");
    };

    const onMessageBills = (event) => {
      const billStream = JSON.parse(event.data);
      if (billStream.restaurant_id.toString() === restaurantId) {
        billStream.random_id = generateUniqueId();
        setTableNumbers((prevTables) => [...prevTables, billStream])
      }
    };

    const onErrorBills = (error) => {
      console.error("Error with SSE Bills:", error);
    };

    const onOpenBills = () => {
      console.log("SSE Bills connection opened.");
    };

    const eventSourceOrders = createEventSourceOrders(onMessageOrders, onErrorOrders, onOpenOrders);
    const eventSourceBills = createEventSourceBills(onMessageBills, onErrorBills, onOpenBills);

    return () => {
      closeEventSourceOrders(eventSourceOrders);
      closeEventSourceBills(eventSourceBills);
    };
  }, []);

  return (
    <>
      <NavigationBar activeLink="orders"/>
      <div>
        <h2 className="orders-title">Ordenes 🛒</h2>
        <OrdersList lastOrders={lastOrders} orders={orders} />
        <Bill tables={tableNumbers} />
      </div>
    </>
  );  
};

export default Orders;
