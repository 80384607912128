import React, { useState } from "react";
import { updateItem } from "../../api/api";
import Snackbar from "../Snackbar/Snackbar";
import "./EditProducts.css";

const EditProducts = ({ groupItems, menuItems }) => {
  const [openSubGroup, setOpenSubGroup] = useState(null);
  const [editingItem, setEditingItem] = useState(null); // Tracks the item being edited
  const [editedItemDetails, setEditedItemDetails] = useState({
    name: "",
    price: "",
    description: "",
    available: false,
    subgroup: "",
  });

  const [snackbar, setSnackbar] = useState({
    message: "",
    color: "",
    show: false,
  });

  const handleShowSnackbar = (message, color) => {
    setSnackbar({ message, color, show: true });

    setTimeout(() => {
      setSnackbar((prevSnackbar) => ({ ...prevSnackbar, show: false }));
      window.location.reload();
    }, 4000);
  };

  const toggleSubGroup = (subgroup) => {
    setOpenSubGroup(openSubGroup === subgroup ? null : subgroup);
  };

  const handleEditClick = (item) => {
    setEditingItem(item.id);
    setEditedItemDetails({
      name: item.name,
      price: item.price,
      description: item.description,
      available: item.available,
      subgroup: item.subgroup,
    });
  };

  const handleSaveClick = async (itemId) => {
    try {
      const response = await updateItem(itemId, editedItemDetails);
      if (response.data) {
        handleShowSnackbar("Su producto se actualizó con éxito :)", "green");
      } else {
        handleShowSnackbar("Ocurrió un error al crear su pedido.", "red");
      }
    } catch (error) {
      handleShowSnackbar(
        "Ocurrió un error al crear su pedido intente de nuevo más tarde.",
        "red"
      );
    }
  };

  const handleCancelClick = () => {
    setEditingItem(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedItemDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className="edit-products">
      <div className="menu-container">
        {groupItems.map((subgroup) => (
          <div key={subgroup} className="menu-subgroup">
            <h2
              className="dropdown-title"
              onClick={() => toggleSubGroup(subgroup)}
            >
              {subgroup}
              {openSubGroup === subgroup ? " ⬆️" : " ⬇️"}
            </h2>
            {openSubGroup === subgroup && (
              <div className="menu-items-container"> {/* Flex container for items */}
                {menuItems
                  .filter((item) => item.subgroup === subgroup)
                  .map((item) => (
                    <div key={item.id} className="menu-item">
                      <div className="item-details">
                        {editingItem === item.id ? (
                          <>
                            <label>
                              Nombre
                              <input
                                type="text"
                                name="name"
                                value={editedItemDetails.name}
                                onChange={handleInputChange}
                                className="edit-input"
                                placeholder="Edit name"
                              />
                            </label>
                            <label>
                              Precio
                              <input
                                type="number"
                                name="price"
                                value={editedItemDetails.price}
                                onChange={handleInputChange}
                                className="edit-input"
                                placeholder="Edit price"
                              />
                            </label>
                            <label>
                              Descripción
                              <input
                                type="text"
                                name="description"
                                value={editedItemDetails.description}
                                onChange={handleInputChange}
                                className="edit-input"
                                placeholder="Edit description"
                              />
                            </label>
                            <label>
                              Categoría
                              <input
                                type="text"
                                name="subgroup"
                                value={editedItemDetails.subgroup}
                                onChange={handleInputChange}
                                className="edit-input"
                                placeholder="Edit category"
                              />
                            </label>
                            <label>
                              Disponible
                              <input
                                type="checkbox"
                                name="available"
                                checked={editedItemDetails.available}
                                onChange={handleInputChange}
                              />
                            </label>
                            <div></div>
                            <button
                              onClick={() => handleSaveClick(item.id)}
                              className="edit-button"
                            >
                              Guardar
                            </button>
                            <button onClick={handleCancelClick} className="cancel-button">
                              Cancelar
                            </button>
                          </>
                        ) : (
                          <>
                            <strong className="item-name">{item.name}</strong>
                            <span
                              className="edit-icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditClick(item)}
                            >
                              ✏️ Editar
                            </span>
                            <p className="item-description">{item.description}</p>
                            <p className="item-price">Precio: ${item.price}</p>
                            {item.available ? (
                              <p className="item-price">Disponible</p>
                            ) : (
                              <p className="item-price">No disponible</p>
                            )}
                            <p className="item-subgroup">Categoría: {item.subgroup}</p>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
        <Snackbar {...snackbar} />
      </div>
    </div>
  );
};

export default EditProducts;
