import React from "react";
import "./navigationBar.css";

export default function NavigationBar({ activeLink }) {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-content">
          <div className="navbar-logo">
            <span className="logo-text">ScanEat 🥡📱</span>
          </div>
            <div className="navbar-links">
              <a href="/orders" className={`nav-link ${activeLink === 'orders' ? 'active' : ''}`}>
                <span className="icon" role="img" aria-label="Shopping Cart">
                  🛒
                </span>
                Ordenes
              </a>
              <a href="/products" className={`nav-link ${activeLink === 'products' ? 'active' : ''}`}>
                <span className="icon" role="img" aria-label="Package">
                  📦
                </span>
                Productos
              </a>
              <span className="nav-link disabled" title="This feature is coming soon!">
                <span className="icon" role="img" aria-label="Bar Chart">
                  📊
                </span>
                Estadísticas
                <span className="coming-soon-label">Próximamente!</span>
              </span>
            </div>
        </div>
      </div>
    </nav>
  );
}
