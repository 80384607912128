import axios from 'axios'

export const createEventSourceOrders = async (onMessage, onError, onOpen) => {
  try {
    const response = await axios.post("/api/initialize-event-source");
    const eventSourceUrl = response.data.eventSourceUrl;
    const eventSource = new EventSource(eventSourceUrl);

    eventSource.onmessage = onMessage;
    eventSource.onerror = onError;
    eventSource.onopen = onOpen;

    return eventSource; 
    } catch (error) {
      console.error('Error creating EventSorce: ', error);
    }
};

export const closeEventSourceOrders = (eventSource) => {
  if (eventSource) {
    eventSource.close();
  }
};

export const createEventSourceBills = async (onMessage, onError, onOpen) => {
  try {
    const response = await axios.post("/api/initialize-event-source-bill");
    const eventSourceUrl = response.data.eventSourceUrl;
    const eventSource = new EventSource(eventSourceUrl);

    eventSource.onmessage = onMessage;
    eventSource.onerror = onError;
    eventSource.onopen = onOpen;

    return eventSource; 
    } catch (error) {
      console.error('Error creating EventSorce: ', error);
    }
};

export const closeEventSourceBills = (eventSource) => {
  if (eventSource) {
    eventSource.close();
  }
};