import React, { useState } from 'react';
import { updateOrder } from "../../api/api";
import Snackbar from "../Snackbar/Snackbar";
import "./ordersList.css";

const formatDate = (date) => {
  const fecha = new Date(date);

  const hours = fecha.getHours().toString().padStart(2, '0');
  const minutes = fecha.getMinutes().toString().padStart(2, '0');
  const seconds = fecha.getSeconds().toString().padStart(2, '0');  

  return `${hours}:${minutes}:${seconds}`;
}

const Order = ({ order, onUpdateStatus }) => {
  const [newStatus, setNewStatus] = useState(order.status);

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setNewStatus(selectedStatus); 
    handleUpdateClick(selectedStatus);
  };

  const handleUpdateClick = (status) => {
    if (onUpdateStatus) {
      onUpdateStatus(order.id, status);
    }
  };

  const getStatusClass = () => {
    switch (newStatus) {
      case 'created':
        return 'status-created';
      case 'in_progress':
        return 'status-in-progress';
      case 'delivered':
        return 'status-delivered';
      default:
        return '';
    }
  };

  return (
    <div className="order-container">
      <p>Pedido para la mesa: {order.table_number}</p>
      {order.date && <p>Hora: {formatDate(order.date)}</p>}
      <ul>
        {order.menu_items.map((item, index) => (
          <li key={index}>
            <strong>{item.name}</strong>
          </li>
        ))}
      </ul>
      <p>Observaciones: {order.extra_fields || "-"}</p>
      
      <div className="status-container">
        <p>Estado:</p>
        <select 
          className={`status-select ${getStatusClass()}`} 
          value={newStatus} 
          onChange={handleStatusChange}
        >
          <option value="created">Recibida</option>
          <option value="in_progress">En preparación</option>
          <option value="delivered">Entregada</option>
        </select>
      </div>
    </div>
  );
};

const OrdersList = ({ lastOrders, orders }) => {
  const [showLastOrders, setShowLastOrders] = useState(false);

  const toggleLastOrdersVisibility = () => {
    setShowLastOrders(!showLastOrders);
  };

  const [snackbar, setSnackbar] = useState({
    message: "",
    color: "",
    show: false,
  });

  const handleShowSnackbar = (message, color) => {
    setSnackbar({ message, color, show: true });

    setTimeout(() => {
      setSnackbar((prevSnackbar) => ({ ...prevSnackbar, show: false }));
      
    }, 4000);
  };

  const updateOrderStatus = async (orderStatus, orderId) => {
    try {
      const response = await updateOrder(orderStatus, orderId);
      if (response.data) {
        handleShowSnackbar("Se actualizó la orden con éxito :)", "green");
      } else {
        handleShowSnackbar("Ocurrió un error al actualizar su orden.", "red");
      }
    } catch (error) {
      handleShowSnackbar("Ocurrió un error al actualizar su orden, intente de nuevo más tarde.", "red");
    }
  };

  return (
    <div>
      {lastOrders.length > 0 ? (
        <p className="show-last-orders" onClick={toggleLastOrdersVisibility} style={{ cursor: 'pointer' }}>
        {showLastOrders ? 'Ocultar ordenes previas ⬆️' : 'Mostrar ordenes previas ⬇️'}
        </p>
      ) : (
        <p className="no-prev-orders">No hay ordenes previas para mostrar.</p>
      )
      }
      {showLastOrders && (
        <div>
          {lastOrders.map((order, index) => (
              <Order 
              key={index} 
              order={order} 
              onUpdateStatus={(orderId, newStatus) => {updateOrderStatus(newStatus, orderId);}} 
            />
          ))}
        </div>
      )}

      {orders.map((order, index) => (
          <Order 
          key={index} 
          order={order} 
          onUpdateStatus={(orderId, newStatus) => {updateOrderStatus(newStatus, orderId);}} 
        />
      ))}
      <Snackbar {...snackbar} />
    </div>
  );
}

export default OrdersList;
