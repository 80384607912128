import React, { useState } from "react";
import { fetchMenuItems } from "./api/api";
import NavigationBar from "./components/Navigation/navigationBar";
import AddProducts from "./components/Products/AddProducts";
import EditProducts from "./components/Products/EditProducts";
import "./Products.css";

const Products = () => {
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [groupItems, setGroupItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const toggleAddVisibility = () => {
    setIsAddVisible(!isAddVisible);
  };

  const toggleEditVisibility = () => {
    setIsEditVisible(!isEditVisible);
  };

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const formatMenuItems = (menuItemsArray) => {
    const groupArr = [];
    menuItemsArray.forEach((menuItem) => {
      groupArr.push(menuItem.subgroup);
    });
    setGroupItems(removeDuplicates(groupArr));
  };

  const fetchMenuItemsData = async () => {
    const restaurantId = localStorage.getItem("restaurantId");
    try {
      const data = await fetchMenuItems(restaurantId);
      formatMenuItems(data.data);
      setMenuItems(data.data);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };


  return (
    <>
      <NavigationBar activeLink="products"/>
      <h2 className="products-title">Productos 📦</h2>
      <div
        className="toggle-container"
        onClick={toggleAddVisibility}
        style={{ cursor: "pointer" }}
      >
        <h2 className="toggle-title">Agregar Productos</h2>
        <span className="toggle-arrow">{isAddVisible ? "⬆️" : "⬇️"}</span>
      </div>

      {isAddVisible && (
        <div className="products-section">
          <AddProducts />
        </div>
      )}

      <div
        className="toggle-container"
        onClick={() => {
          toggleEditVisibility();
          fetchMenuItemsData();
        }}
        style={{ cursor: "pointer" }}
      >
        <h2 className="toggle-title">Editar Productos</h2>
        <span className="toggle-arrow">{isEditVisible ? "⬆️" : "⬇️"}</span>
      </div>

      {isEditVisible && (
        <div className="products-section">
          <EditProducts groupItems={groupItems} menuItems={menuItems}/>
        </div>
      )}
    </>
  );
};

export default Products;
