import React from "react";
import "./OrderModal.css";

const OrderModal = ({
  selectedItems,
  extraInfo,
  handleCloseModal,
  handleOrderSubmit,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={handleCloseModal}>
            &times;
          </span>
          <h2>Productos seleccionados</h2>
          <ul>
            {selectedItems.map((itemName, index) => (
              <li key={index}>{itemName}</li>
            ))}
          </ul>
          <div className="extra-fields">
            <p>Observaciones: {extraInfo}</p>
          </div>
          <button className="back-button" onClick={handleCloseModal}>
            Volver
          </button>
          <button className="create-button" onClick={handleOrderSubmit}>
            Crear orden
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
